// 基础配置
let normalServiceUrl = 'https://go-endpoint.replit.app';

// 定义默认的模型服务URL
let modelServiceUrl;
// 海外服-云端用
let modelServiceUrl_1 = 'https://zchat-model-service-1-eric166188.replit.app';

// 检查当前主机名
if (window.location.hostname === 'localhost') {
    // 本地环境
    modelServiceUrl = 'http://localhost:8000';
} else {
    // 云端环境
    modelServiceUrl = 'https://zchat-model-service-1-eric166188.replit.app';
}

const headers = {
    "OpenAI-Beta": "assistants=v2",
    'Content-Type': 'application/json',
}
const assistantId = "asst_5WFdXpWbssQZXOsyI2aeKK44"

async function fetchApi(endpoint, method = 'POST', data = {}, stream, header = null) {
    try {
        const response = await fetch(`${normalServiceUrl}${endpoint}`, {
            headers: header === null ? headers : header,
            method: method,
            body: header !== null ? data : (method === 'GET' ? null : JSON.stringify(data)),
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }

        if (stream) {
            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');
            let result = '';
            let finalResponse = {};

            while (true) {
                const {value, done} = await reader.read();
                if (done) break;
                result += decoder.decode(value, {stream: true});

                const events = result.split('\n\n');
                result = events.pop();  // 保留最后一个未完成的事件

                for (let event of events) {
                    if (event.trim() === '') continue;
                    const [eventTypeLine, dataLine] = event.split('\n');
                    const eventType = eventTypeLine.replace('event: ', '').trim();
                    let eventData;

                    try {
                        eventData = JSON.parse(dataLine.replace('data: ', '').trim());
                    } catch (e) {
                        // console.log(`Non-JSON data: ${dataLine}`);
                        eventData = dataLine.replace('data: ', '').trim();
                    }

                    // handleStreamData(eventType, eventData);
                    if (eventType === 'thread.message.completed') {
                        finalResponse = eventData;
                    }
                }
            }
            return finalResponse;

        } else {
            return await response.json();  // 直接返回 JSON 响应
        }
    } catch (error) {
        console.error(`Failed to fetch ${endpoint}:`, error);
        return null;
    }
}

// 创建会话（线程）
export async function createThread(messages) {
    return fetchApi('/threads', 'POST', messages);
}

// 获取对话消息列表
export async function getThreadMessages(thread_id) {
    return fetchApi(`/threads/${thread_id}/messages`, 'GET');
}


// 发送聊天请求（发起对话）
export async function postRunByThreadBot(thread_id, instructions, assistant_id = assistantId) {
    let data = {
        "model": "gpt-4o-mini",
        "temperature": 0.1,
        "assistant_id": assistant_id,
        "stream": true,
        instructions,
    };
    return fetchApi(`/threads/${thread_id}/runs`, 'POST', data, true);
}

export async function postRunByThreadBotCloud(thread_id, instructions, assistant_id = assistantId) {
    let data = {
        "thread_id": thread_id,
        "assistant_id": assistant_id,
        "instructions": instructions,
    };

    const response = await fetch(modelServiceUrl+`/api/chat/run_thread`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        const errorData = await response.json();
        console.error('Error:', errorData);
        throw new Error(`Request failed with status ${response.status}`);
    }

    return response;
}

export async function postMessageByThreadBot(thread_id, role, content, file) {
    // 定义基础的消息数据
    let data = {
        "role": role,
        "content": []
    };

    // 检查是否有文本内容，若有则添加
    if (content && content.trim()) {
        data.content.push({
            "type": "text",
            "text": content.trim() // 确保去除多余的空白
        });
    }

    // 检查是否有文件，若有则添加
    if (file && file.id) {
        data.content.push({
            "type": "image_file",
            "image_file": {
                "file_id": file.id,
                "detail": "low"
            }
        });
    }

    // 确保只有当 content 或 file 存在时才发送请求
    if (data.content.length > 0) {
        return await fetchApi(`/threads/${thread_id}/messages`, 'POST', data);
    } else {
        console.warn("No content or file to send.");
        return null;  // 没有内容或文件时，不发送请求
    }
}

//删除某一条消息
export async function deleteMessageApi(thread_id, message_id) {
    return fetchApi(`/threads/${thread_id}/messages/${message_id}`, 'DELETE')
}

export async function uploadPictureFileAPi(file) {//只允许上传图片等资料
    const formData = new FormData();
    formData.append('file', file);
    formData.append('purpose', 'vision');
    return fetchApi(`/files`, 'POST', formData, false, {
        "OpenAI-Beta": "assistants=v2",
    })
}

export async function uploadFileAPi(file) {//允许上传文件
    const formData = new FormData();
    formData.append('file', file);
    formData.append('purpose', 'assistants');
    return fetchApi(`/files`, 'POST', formData, false, {
        "OpenAI-Beta": "assistants=v2",
    })
}

//请求图片内容的接口
export async function getPictureContentApi(id) {
    const response = await fetch(`${modelServiceUrl}/files/${id}/content`);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    } else {
        return response.blob()
    }
}

//获取文件内容
export async function getFileContentApi(id) {
    return fetchApi(`/files/${id}`, 'GET');
}

//修改已读消息
export async function modifyMessageReadApi(thread_id, message_id, data) {
    return fetchApi(`/threads/${thread_id}/messages/${message_id}`, 'POST', data)
}

// 查询对话结果(会话消息)
// export async function chatRetrieve(conversation_id, chat_id) {
//     return fetchApi(`/v3/chat/retrieve?conversation_id=${conversation_id}&chat_id=${chat_id}`, 'GET');
// }

