// UserChat.jsx
import React, {useEffect, useState, useRef} from 'react';
import {
    Input,
    Button,
    Tooltip,
    Space, Upload, Avatar, Empty, Tag, Typography, Spin
} from '@arco-design/web-react';
import {
    IconFolderAdd,
    IconSend,
} from '@arco-design/web-react/icon';
import {sendMessageApi} from '../../../api/normalApi';
import {modifyMessageReadApi} from "../../../api/openapi";

const {Text} = Typography;

const UserChat = ({currentWxUser, currentWxMyAccount, messages, setMessages, imageUrls, getMessageList}) => {
    const [inputValue, setInputValue] = useState('');
    const contentRef = useRef(null);
    const [isUserScrolling, setIsUserScrolling] = useState(true); // Whether user is scrolling

    useEffect(() => {
        if (contentRef.current) {
            contentRef.current.scrollTo({
                top: contentRef.current.scrollHeight,
                behavior: 'smooth'
            });
        }

        // Mark messages as read
        if (messages && messages.length > 0) {
            modifyMessageRead();
        }

    }, [messages, isUserScrolling]);

    const modifyMessageRead = async () => {
        const latestMessage = messages.find((item) => item.thread_id === currentWxUser.thread_id);
        if (!latestMessage) return;


        const data = {
            metadata: {
                "readTime": String(Math.floor(Date.now() / 1000))
            }
        };
        try {
            await modifyMessageReadApi(latestMessage.thread_id, latestMessage.id, data);
            // Optionally handle the response
        } catch (error) {
            console.error("Error marking messages as read:", error);
        }
    };

    const handleSend = async () => {
        if (!inputValue.trim()) return;

        const back_data = {
            "wx_user_id": currentWxMyAccount.wx_user_id,
            "to_user_id": currentWxUser.to_user_id, // Removed `.current`
            msg: inputValue
        };
        setInputValue('');
        try {
            const response = await sendMessageApi(back_data);
            if (response.status === 200) {
                getMessageList(currentWxUser.thread_id).then((data) => {
                    setMessages(data.data);
                })
            }
        } catch (error) {
            console.error("Error sending message:", error);
        }
    };

    // Format timestamp to time string
    const formatTimestampToTime = (timestamp) => {
        const date = new Date(timestamp * 1000);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };

    if (!currentWxUser) {
        return (
            <Space direction="vertical"
                   style={{width: "40vw", height: "91vh", boxShadow: '0px 0 6px rgba(0, 0, 0, 0.08)', marginLeft: 5}}
                   align={"start"}>
                <Empty description='请选择一个用户进行聊天' style={{marginTop: "30vh", width: "44vw"}}/>
            </Space>
        );
    }

    return (
        <Space direction="vertical"
               style={{width: "45vw", height: "92vh", boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)', marginLeft: 5}}
               align={"start"}>
            <Space direction="vertical"
                   style={{
                       height: "80vh",
                       width: "44vw",
                       overflowY: 'auto',
                       scrollbarWidth: 'thin',
                       paddingLeft: 15,
                       paddingTop: 10,
                       paddingBottom: 10,
                       scrollbarColor: '#EEE white',
                       boxShadow: '0 2px 2px rgba(0, 0, 0, 0.03)'
                   }} ref={contentRef}>

                {messages.length === 0 ?
                    <Space align="center" style={{width: '100%', height: '70vh', justifyContent: 'center'}}>
                        <Spin dot/> {/* Loading animation */}
                    </Space> : messages.slice().reverse().map((msg, index) => {
                        return (
                            <Space key={msg.id || index} align={"start"}>
                                <Avatar>
                                    <img alt='avatar'
                                         src={msg.role === "user" ? `data:image/png;base64,${currentWxUser.head_img}` :
                                             `data:image/png;base64,${currentWxMyAccount.head_img}`
                                         }/>
                                </Avatar>
                                <Space direction={"vertical"}>
                                    <Space direction='horizontal'>
                                        <Text>{msg.role === "user" ? currentWxUser.nickname : currentWxMyAccount.wx_nickname}</Text>
                                        <Text type="secondary" style={{fontSize: '12px'}}>
                                            {formatTimestampToTime(msg.created_at)}
                                        </Text>
                                        {msg.role === 'user' ?
                                            <Tag color={'green'} style={{color: '#00B42A'}}>客户</Tag> :
                                            <Tag color={'orange'} style={{color: '#FF7D00'}}>销售</Tag>}
                                    </Space>
                                    <Space direction={"vertical"}>
                                        {msg.content.map((item, contentIndex) => {
                                            if (item.type === "text" && item.text && item.text.value) {
                                                return (
                                                    <React.Fragment key={`${msg.id}-${contentIndex}`}>
                                                        {item.text.value.split(/(?<=[？?!。])/).map((sentence, sentenceIndex) => (
                                                            <Space key={`${msg.id}-${contentIndex}-${sentenceIndex}`}
                                                                   align={"center"}>
                                                                <Text
                                                                    style={{
                                                                        background: msg.role === "user" ? "#366ef4" : "#f7f7fa",
                                                                        color: msg.role === "user" ? "#000000" : "#ffffff",
                                                                        padding: "10px",
                                                                        borderRadius: "8px",
                                                                        backgroundColor: msg.isTyping
                                                                            ? "#65B0F4"
                                                                            : msg.role === "user"
                                                                                ? "#F7F7FA"
                                                                                : "#366EF4",
                                                                        display: "inline-block",
                                                                        width: "auto",
                                                                    }}
                                                                >
                                                                    {sentence.replace(/[？?！!。，,；;、]+$/g, "")}
                                                                </Text>
                                                            </Space>
                                                        ))}
                                                    </React.Fragment>
                                                );
                                            } else if (item.type === "image_file" && imageUrls[item.image_file.file_id]) {
                                                return (
                                                    <Space key={item.image_file.file_id}>
                                                        <img
                                                            src={imageUrls[item.image_file.file_id]}
                                                            alt="用户图片"
                                                            style={{width: '250px', height: 'auto'}}/>
                                                    </Space>
                                                );
                                            }
                                            return null;
                                        })}
                                    </Space>
                                </Space>
                            </Space>
                        );
                    })}
            </Space>
            <Space style={{marginTop: 20, marginLeft: "5vw"}}>
                <Tooltip content="选择文件发送">
                    <Upload
                        action={null}
                        showUploadList={false}
                        // fileList={pic ? [pic] : []}
                        multiple={false}
                        // onChange={(_, currentFile) => handleUploadPic(_, currentFile)}
                        accept=".png,.jpeg,.jpg"
                    >
                        <IconFolderAdd style={{width: "20px", height: "20px"}}/>
                    </Upload>
                </Tooltip>
                <Input
                    value={inputValue}
                    onChange={(value) => setInputValue(value)}
                    onPressEnter={handleSend}
                    style={{borderRadius: '3vh', height: '5vh', width: "30vw", marginLeft: 10}}
                />
                <Tooltip content="发送消息">
                    <Button
                        type="text"
                        icon={<IconSend style={{width: "20px", height: "20px", marginLeft: 10}}/>}
                        onClick={handleSend}
                        disabled={inputValue.trim() === ''}
                    />
                </Tooltip>
            </Space>
        </Space>
    );
};

export default UserChat;