import React, {useEffect, useRef, useState} from 'react';
import {Input, Button, Space, Message} from '@arco-design/web-react';
// import SalesProcess from './components/EditPage/SalesProcess';
// import SalesMaterial from "../../SalesMaterial/SalesMaterial";
import {updateAssistantApi, getAssistantsIdApi, getProductsApi} from "../../../api/normalApi";
import ProductList from "../../SalesMaterial/components/ProductList";
import CaseMaterial from "../../SalesMaterial/components/CaseMaterial";
import SalesProcess from "./components/EditPage/SalesProcess";
import ChatTest from "./ChatTest"; // 引入API

const {TextArea} = Input;

const EditPage = ({
                      draftInstructions,
                      setDraftInstructions,
                      roleInstructions,
                      setRoleInstructions,
                      selectedEmployee,
                      setShowPart,
                      setSalesProcess,
                      salesProcess
                  }) => {
    const [editing, setEditing] = useState(false);
    const [name, setName] = useState('新员工');
    const [products, setProducts] = useState([]);
    const inputRef = useRef(null);

    // 获取员工数据并更新状态
    const fetchAssistantData = async (id) => {
        try {
            const response = await getAssistantsIdApi(id);
            if (response.status === 'success') {
                const data = response.data;
                // console.log(data)
                setName(data.name);
                setDraftInstructions(data.draft_instructions);
                setRoleInstructions(data.role_instructions);
                setSalesProcess(data.sales_process);
            } else {
                Message.error('获取员工信息失败');
            }
        } catch (error) {
            Message.error('请求失败，请重试');
            console.error(error);
        }
    };

    // 获取员工信息
    useEffect(() => {
        if (selectedEmployee) {
            fetchAssistantData(selectedEmployee.id).then(r => {
            })
            getProductsApi().then(r => {
                const filteredProducts = r.data.map(item => ({
                    name: item.name,
                    price: item.price,
                    description: item.description,
                    urls: item.urls
                }));
                setProducts(filteredProducts);
            });
        }
        // eslint-disable-next-line
    }, [selectedEmployee]);

    // 保存名称
    const handleSaveName = () => {
        updateAssistantApi(selectedEmployee.id, {
            name: name,
        }).then(async data => {
            if (data.status === 'success') {
                Message.success('名称已修改');
                fetchAssistantData(selectedEmployee.id).then(r => {
                })
            } else {
                Message.error('修改失败，员工名不能为空');
                console.log(data);
            }
        });
        setEditing(false);
    };

    useEffect(() => {
        if (editing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [editing]);

    // 更新角色信息
    const updateAssistant = async () => {
        // 把products 数组转为字符串给productInstructions
        const productInstructions = products.map(item => {
            return `${item.name} ${item.price}元 ${item.description} ${item.urls}`
        }).join('\n');

        setDraftInstructions(roleInstructions +
            '\n\n销售流程为：\n' + salesProcess +
            '\n\n我们的产品有：\n' + productInstructions );
        const newEmployee = {
            draft_instructions: draftInstructions,
            role_instructions: roleInstructions,
            sales_process: salesProcess
        }
        await updateAssistantApi(selectedEmployee.id, newEmployee);
    }

    return (
        <Space style={{height: "100vh", paddingRight: 8}} align={"start"}>
            <Space direction={"vertical"}>
                <Space style={{marginTop:12}}>
                    <h3 style={{marginBottom:0,marginTop:0}}>员工信息</h3>
                    <Input
                        ref={inputRef}
                        value={name}
                        onChange={setName}
                        onBlur={handleSaveName}
                        onPressEnter={handleSaveName}
                        placeholder='未命名的新员工'
                        style={{width: "calc(28vw - 142px)"}}
                    />
                    <Button size='small' type='outline' onClick={() => setShowPart(false)}>返回</Button>
                </Space>
                <TextArea
                    style={{width: "28vw"}}
                    placeholder={"填写人物设定"}
                    autoSize={{minRows: 10}}
                    value={roleInstructions}
                    onChange={(v) => {
                        setRoleInstructions(v);
                    }}
                    onBlur={() => {
                        updateAssistant().then(r => {
                        })
                    }}
                />
                <ProductList
                    columns={['name', 'price', 'actions']}
                />
                <CaseMaterial
                    columns={['case_name', 'description', 'actions']}
                />
            </Space>
            <Space direction={"vertical"} style={{paddingLeft:5}}>
                <h3 style={{marginBottom: 4, marginTop: 16}}>聊天话术</h3>
                <Input.TextArea
                    value={salesProcess}
                    onChange={(e) =>
                        setSalesProcess(e)
                    }
                    onBlur={() => {
                        updateAssistant().then(r => {
                        })
                    }}
                    placeholder="请输入销售流程"
                    style={{width: "30vw",height: "94vh",
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#EEE white'}}
                />
            </Space>



            <ChatTest
                draftInstructions={draftInstructions}
                selectedEmployee={selectedEmployee}
            />
        </Space>
    );
};

export default EditPage;