import React, { useState, useEffect } from 'react';
import { Card, Input, Typography, Space, InputNumber, Modal, Button } from '@arco-design/web-react';

const ProductCardModal = ({ visible, onClose, product, setProduct, handleDelete }) => {
    // 当 product 改变时，更新组件内的状态
    useEffect(() => {
        if (product) {
            setProductName(product.name || '');
            setPrice(product.price || 50);
            setDescription(product.description || '');
            setUrls(product.urls || '');
        } else {
            setProductName('');
            setPrice(0);
            setDescription('');
            setUrls('');
        }
    }, [product]);

    const [productName, setProductName] = useState(product ? product.name : '');
    const [price, setPrice] = useState(product ? product.price : 50);
    const [description, setDescription] = useState(product ? product.description : '');
    const [urls, setUrls] = useState(product ? product.urls : '');

    const handleOk = () => {
        // 更新 product 对象
        setProduct({
            ...product,
            name: productName,
            price: price,
            description: description,
            urls: urls,
        });
        onClose(); // 关闭对话框
    };

    return (
        <Modal
            visible={visible}
            footer={
                <Space>
                    {product ? <Button type='primary' status='danger' onClick={() => handleDelete()}>
                        删除
                    </Button> : ''}
                    <Button type='outline' onClick={() => onClose()}>取消</Button>

                    <Button type='primary' onClick={() => handleOk()}>
                        确定
                    </Button>
                </Space>
            }
            style={{ width: 450 }}
            closable={false}
            title={
                <Space style={{ width: "100%" }}>
                    <h3>编辑商品</h3>
                </Space>}>
            <Card bordered={false} style={{ boxShadow: 'none' }}>
                <Space direction="vertical" style={{ width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Input
                            value={productName}
                            onChange={(value) => {
                                setProductName(value);
                            }}
                            placeholder="请输入商品名称"
                            style={{ width: '100%' }}
                        />
                        <InputNumber
                            value={price}
                            onChange={(value) => {
                                setPrice(value);
                            }}
                            suffix="元"
                            style={{ width: 100 }}
                        />
                    </div>
                    <div>
                        <Typography.Text type="secondary">商品描述</Typography.Text>
                        <Input.TextArea
                            placeholder="请输入商品描述"
                            value={description}
                            onChange={(value) => {
                                setDescription(value);
                            }}
                            autoSize
                            style={{ marginTop: 8 }}
                        />
                    </div>
                    <div>
                        <Typography.Text type="secondary">访问链接</Typography.Text>
                        <Input
                            placeholder="请输入访问链接"
                            value={urls}
                            onChange={(value) => {
                                setUrls(value);
                            }}
                        />
                    </div>
                </Space>
            </Card>
        </Modal>
    );
};

export default ProductCardModal;
