const chatServiceUrl = 'https://chat.zhihua.chat/shop'
const isLocal = (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') && document.title === '智话销售';
const wxCloudServiceUrl = isLocal ? 'http://localhost:80' : 'https://zchat-bg-go-1-116838-7-1320884641.sh.run.tcloudbase.com';

async function getToken() {
    const token = localStorage.getItem('sales_token');
    return token ? `Bearer ${token}` : null;
}

async function fetchTokenApi(url, options = {}) {
    try {
        const token = await getToken();
        if (token) {
            options.headers = {
                ...(options.headers || {}),
                'Authorization': token
            };
        } else {
            window.location.href = '/login';
            console.log("未登录");
            return;
        }

        if (!options.headers['Content-Type']) {
            options.headers['Content-Type'] = 'application/json';
        }

        // 确保 body 被正确转换为字符串
        if (options.body && typeof options.body === 'object') {
            options.body = JSON.stringify(options.body);
        }

        const response = await fetch(`${url}`, options);
        if (response.status === 401) {
            window.location.href = '/login';
            console.log("无权限");
            return;
        }
        if (response.status !== 200) {
            console.log('Error fetching API', response);
        }
        return response.json();

    } catch (error) {
        console.log('Network response was not ok', error);
    }
}

export async function loginWechatApi() {//登陆微信
    return fetchTokenApi(`${chatServiceUrl}/login_wechat`, { method: "POST" })
}

export async function logoutWechatApi(data) {//登出微信
    return fetchTokenApi(`${chatServiceUrl}/login_out`, { method: "POST", body: data })
}

export async function canelWechatApi(data) {//取消登陆微信
    return fetchTokenApi(`${chatServiceUrl}/cancel_login`, { method: "POST", body: data })
}

export async function bindAgentApi(data) {//微信绑定agent
    return fetchTokenApi(`${chatServiceUrl}/bind_agent`, { method: "POST", body: data })
}

export async function unBindAgentApi(data) {//微信解绑agent
    return fetchTokenApi(`${chatServiceUrl}/unbind_agent`, { method: "POST", body: data })
}

export async function getWxAccountsApi(need_head = true) {//获取所有微信账号
    return fetchTokenApi(`${chatServiceUrl}/get_wx_accounts?need_head=${need_head}`, { method: "GET" })
}

export async function getWxUsersApi(wxAccount, need_head = true) {//获取微信助手的消息
    return fetchTokenApi(`${chatServiceUrl}/get_wx_users?wx_user_id=${wxAccount}&need_head=${need_head}`, { method: "GET" })
}

export async function sendMessageApi(data) {//微信自定义发送消息
    return fetchTokenApi(`${chatServiceUrl}/send_message`, { method: "POST", body: data })
}

export async function login(username, password) {
    return fetch(`${wxCloudServiceUrl}/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            username,
            password
        })
    }).then(response => {
        if (response.status !== 200) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    });
}

export async function addProductApi(data) {//新增商品
    return fetchTokenApi(`${wxCloudServiceUrl}/products`, { method: "POST", body: data })
}

export async function getProductsApi() {//获取商品
    return fetchTokenApi(`${wxCloudServiceUrl}/products`, { method: "GET" })
}

export async function updateProductApi(id, data) {//修改商品
    return fetchTokenApi(`${wxCloudServiceUrl}/products?id=${id}`, { method: "PUT", body: data })
}

export async function deleteProductApi(id) {//删除商品
    return fetchTokenApi(`${wxCloudServiceUrl}/products?id=${id}`, { method: "DELETE" })
}


export async function addCaseApi(data) {//新增案例
    return fetchTokenApi(`${wxCloudServiceUrl}/cases`, { method: "POST", body: data })
}

export async function getCasesApi() {//获取案例
    return fetchTokenApi(`${wxCloudServiceUrl}/cases`, { method: "GET" })
}

export async function updateCaseApi(id, data) {//修改案例
    return fetchTokenApi(`${wxCloudServiceUrl}/cases?id=${id}`, { method: "PUT", body: data })
}

export async function deleteCaseApi(id) {//删除案例
    return fetchTokenApi(`${wxCloudServiceUrl}/cases?id=${id}`, { method: "DELETE" })
}

export async function addAssistantApi(data) {//新增员工
    return fetchTokenApi(`${wxCloudServiceUrl}/assistants`, { method: "POST", body: data })
}

export async function getAssistantsApi() {//获取员工
    return fetchTokenApi(`${wxCloudServiceUrl}/assistants`, { method: "GET" })
}

export async function getAssistantsIdApi(assistants_id) {//获取员工
    return fetchTokenApi(`${wxCloudServiceUrl}/assistants/${assistants_id}`, { method: "GET" })
}

export async function updateAssistantApi(id, data) {//修改员工
    return fetchTokenApi(`${wxCloudServiceUrl}/assistants?id=${id}`, { method: "PUT", body: data })
}

export async function deleteAssistantApi(id) {//TODO：暂时的接口：删除员工
    return fetchTokenApi(`${wxCloudServiceUrl}/assistants/${id}`, { method: "DELETE" })
}

// 新增接口：获取员工及其微信账号
export async function getAssistantsWithWeChatApi() {
    return fetchTokenApi(`${wxCloudServiceUrl}/assistants/wechat`, { method: "GET" });
}

// 新增接口：更新微信账号的自动回复状态
export async function updateWechatAutoReplyApi(wxID, autoReply) {
    return fetchTokenApi(`${wxCloudServiceUrl}/wechats/update?wx_id=${wxID}&auto_reply=${autoReply}`, { method: "PUT" });
}

// 新增接口：查看微信列表以及绑定状态
export async function getWeChats() {
    return fetchTokenApi(`${wxCloudServiceUrl}/wechats`, { method: "GET" });
}



// 获取临时密钥的函数，调用STS API
export async function getTempCredentials() {
    const response = await fetch(
        `https://sts.tencentcloudapi.com/?Action=GetFederationToken&Version=2018-08-13&DurationSeconds=1800&Region=ap-guangzhou&Name=test&Policy=${encodeURIComponent(JSON.stringify({
            "version": "2.0",
            "statement": [
                {
                    "action": [
                        "name/cos:PutObject"
                    ],
                    "effect": "allow",
                    "resource": [
                        "qcs::cos:ap-guangzhou:1256349444/zcloud-1256349444/*" // 设置你具体的存储桶
                    ]
                }
            ]
        }))}`,
        {
            method: 'GET',
        }
    );
    const data = await response.json();
    console.log(data)
    return data.Response.Credentials;
};