// src/pages/AgentList/ListPage/component/EmployeeTable.js

import React, { useState, useEffect, useCallback } from 'react';
import { Table, Switch, Space, Button, Message, Modal } from '@arco-design/web-react';
import { IconWechat } from '@arco-design/web-react/icon';
import {
    addAssistantApi, // 添加员工的接口
    getAssistantsWithWeChatApi,
    logoutWechatApi,
    updateWechatAutoReplyApi
} from "../../../../api/normalApi";

function EmployeeTable({ setSelectedEmployee }) { // 确保 Prop 名称正确
    const [employeeList, setEmployeeList] = useState([]);
    const maxSeats = 10; // 最大员工数

    // 处理员工和微信账号数据
    const processEmployeeData = (data) => {
        let result = [];
        data.forEach((employee) => {
            if (employee.wechats.length === 0) {
                // 没有绑定微信的情况
                result.push({
                    id: employee.assistant.id,
                    name: employee.assistant.name,
                    wx_id: null,                 // 没有微信账号
                    nickname: '未绑定',            // 显示“未绑定”
                    auto_reply: false,           // 自动回复设为 false
                });
            } else {
                employee.wechats.forEach((wechat, index) => {
                    result.push({
                        id: employee.assistant.id,
                        name: index === 0 ? employee.assistant.name : '', // 只在第一行显示员工名字
                        wx_id: wechat.wx_id,
                        nickname: wechat.nickname,
                        auto_reply: wechat.auto_reply,
                        is_online: wechat.is_online
                    });
                });
            }
        });
        return result;
    };

    // 获取员工及微信账号数据
    const getAssistantsWxIds = async () => {
        try {
            const { data } = await getAssistantsWithWeChatApi(); // 调用新接口获取数据
            const processedData = processEmployeeData(data); // 处理数据
            setEmployeeList(processedData);
        } catch (error) {
            Message.error('获取员工及微信账号信息失败');
        }
    };

    useEffect(() => {
        getAssistantsWxIds(); // 获取数据
    }, []);

    // 更新自动回复开关
    const handleSwitchChange = async (wxID, newAutoReplyStatus) => {
        try {
            await updateWechatAutoReplyApi(wxID, newAutoReplyStatus); // 调用 API 更新状态

            // 更新状态
            const updatedList = employeeList.map((item) =>
                item.wx_id === wxID ? { ...item, auto_reply: newAutoReplyStatus } : item
            );
            setEmployeeList(updatedList);
            Message.success('自动回复状态更新成功');
        } catch (error) {
            Message.error('更新自动回复状态失败，请稍后重试');
        }
    };

    // 选择员工，点击“编辑”按钮时调用
    const handleSelectedEmployee = (row) => {
        setSelectedEmployee(row);  // 传递选中的员工数据
    };

    // 确认退出微信
    const confirmLogout = (accountId) => {
        Modal.confirm({
            title: '确认退出登录吗？',
            content:
                <div style={{ textAlign: 'center' }}>
                    <p>退出登录微信后将不再接收和回复消息</p>
                </div>,
            onOk: async () => {
                logoutWx(accountId)
            },
        });
    }

    //登出微信
    const logoutWx = useCallback(async (accountId) => {
        logoutWechatApi({ wx_user_id: accountId }).then(async data => {
            if (data.status === 200) {
                await getAssistantsWxIds();
                Message.success('退出登录成功')
            }
        })
    }, [])

    // 添加员工
    const handleAddEmployee = async () => {
        const remainingSeats = maxSeats - employeeList.length; // 剩余席位
        if (remainingSeats > 0) {
            const newEmployee = {
                name: `新员工`,
                account: null,
                auto_reply: false,
                instructions: "测试",
                sales_process: "{}"
            };

            try {
                const data = await addAssistantApi(newEmployee); // 调用添加员工 API
                if (data.status === 'success') {
                    await getAssistantsWxIds(); // 刷新员工列表
                    Message.success(`已添加 ${newEmployee.name}`);
                } else {
                    Message.error('添加员工失败，请稍后重试');
                }
            } catch (error) {
                Message.error('添加员工失败，请稍后重试');
            }
        } else {
            Message.warning('已无剩余席位');
        }
    };

    // 动态生成表格列
    const columns = [
        {
            title: '数字员工',
            dataIndex: 'name',
            key: 'name',
            render: (name) => <span>{name || ' '}</span>,
        },
        {
            title: '发布账号',
            dataIndex: 'nickname',
            key: 'nickname',
            render: (nickname, row) => (
                <Space>
                    <IconWechat style={{ color: '#00B42A' }} />
                    <span style={{ color: row.is_online ? 'black' : '#C9CDD4' }}>{nickname}</span>
                </Space>
            ),
        },
        {
            title: '自动回复',
            dataIndex: 'auto_reply',
            key: 'auto_reply',
            render: (autoReply, record) => (
                <Switch
                    checked={autoReply}
                    disabled={!record.wx_id} // 如果没有绑定微信，禁用开关
                    onChange={(checked) => handleSwitchChange(record.wx_id, checked)}
                />
            ),
        },
        {
            title: '编辑配置',
            dataIndex: 'edit',
            key: 'edit',
            render: (_, row) => (
                <Button type="text" onClick={() => handleSelectedEmployee(row)}>
                    编辑
                </Button>
            ),
        },
        {
            title: '操作',
            dataIndex: 'logout',
            key: 'logout',
            render: (_, row) => (
                row.wx_id ? <Button type="text" onClick={() => { row.is_online ? confirmLogout(row.wx_id) : console.log('haah') }}>
                    {row.is_online ? '退出' : '登录'}
                </Button> : null
            ),
        },
    ];

    return (
        <div style={{ width: "60vw" }}>
            <Table
                columns={columns}
                data={employeeList}
                rowKey={(record) => record.wx_id || record.id} // 如果没有微信账号，使用员工 ID 作为 key
                pagination={false}
            />
            <div style={{ marginTop: '10px', cursor: 'pointer' }}>
                <Button type="text" onClick={handleAddEmployee} disabled={employeeList.length >= maxSeats}>
                    添加员工
                </Button>
                <span style={{ float: 'right' }}>剩余席位 {maxSeats - employeeList.length} 个</span>
            </div>
        </div>
    );
}

export default EmployeeTable;