import React, {useEffect, useRef, useState} from 'react';
import {
    Avatar,
    Button,
    Empty,
    Input, Message,
    Space,
    Tabs,
    Tooltip,
    Typography,
    Upload
} from '@arco-design/web-react';
import {IconRefresh, IconCopy, IconDelete, IconFileImage, IconSend} from '@arco-design/web-react/icon';
import {
    createThread,
    deleteMessageApi,
    getPictureContentApi,
    getThreadMessages,
    postMessageByThreadBot, postRunByThreadBot, postRunByThreadBotCloud, uploadPictureFileAPi
} from '../../../api/openapi';
import LaunchModal from './components/ChatTest/LaunchModal';
import {formatTimestampToTime} from '../../../utils/format';

const {Text} = Typography;
const TabPane = Tabs.TabPane;

const ChatTest = ({draftInstructions, selectedEmployee}) => {
    const [threadId, setThreadId] = useState("");
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [replyMode, setReplyMode] = useState("quick");
    const [imageUrls, setImageUrls] = useState({}); // 存储图片的URL
    const [file, setFile] = React.useState();
    const [isUserScrolling, setIsUserScrolling] = useState(false);//是否允许气泡content滑动
    const contentRef = useRef(null);
    const disableValue = useRef(false)//是否允许输入框输入

    const scrollToBottom = () => {
        if (contentRef.current) {
            contentRef.current.scrollTo({
                top: contentRef.current.scrollHeight,
                behavior: 'smooth'
            });
        }
    };

    // 初始化消息
    useEffect(() => {
        // 获取本地存储的回复模式
        let mode = JSON.parse(localStorage.getItem('reply_mode'));
        if (mode) {
            setReplyMode(mode);
        } else {
            setReplyMode("quick");
        }

        let thread = JSON.parse(localStorage.getItem('thread_id'));
        if (thread) {
            setThreadId(thread);
            getMessageList(thread).then(() => {
                scrollToBottom(); // 初始加载时滚动到底部
            });
        } else {
            setMessages([]);
            newThreadHandler().then((threadId) => {
                scrollToBottom(); // 新建线程后滚动到底部
            });
        }
        // eslint-disable-next-line
    }, []);

    //控制气泡出现是否滑动
    useEffect(() => {
        if (!isUserScrolling) {
            scrollToBottom();
        }
    }, [messages, isUserScrolling]);

    // 更改回复模式
    const changeReplyMode = (mode) => {
        setReplyMode(mode);
        localStorage.setItem('reply_mode', JSON.stringify(mode));
    }

    // 获取消息列表，增加保护机制
    const getMessageList = async (threadId) => {
        try {
            const data = await getThreadMessages(threadId);
            if (!data || !data.data) {
                throw new Error('获取消息数据失败');
            }

            const messages = data.data;

            // 获取图片内容并生成URL
            const imagePromises = messages.map(async (msg) => {
                if (!msg || !msg.content) return; // 确保 msg 和 content 存在

                // 遍历消息中的内容
                const contentPromises = msg.content.map(async (item) => {
                    if (item.type === "image_file" && item.image_file && item.image_file.file_id) {
                        try {
                            const blob = await getPictureContentApi(item.image_file.file_id);
                            if (blob) {
                                const imageUrl = URL.createObjectURL(blob);
                                // 更新图片的URL到状态中
                                setImageUrls((prev) => ({
                                    ...prev,
                                    [item.image_file.file_id]: imageUrl,
                                }));
                            }
                        } catch (error) {
                            console.error(`获取图片内容失败: ${item.image_file.file_id}`, error);
                        }
                    }
                });

                return Promise.all(contentPromises); // 等待所有内容异步操作完成
            });

            await Promise.all(imagePromises); // 确保所有图片获取操作都完成
            setMessages(messages); // 更新消息列表
        } catch (error) {
            console.error('获取消息列表失败:', error);
            Message.error('获取消息列表失败，请稍后再试。'); // 显示错误信息给用户
        }
    };

    // 渲染最后一句回复
    const getMessageReply = async (threadId) => {
        if (replyMode === "quick") {
            getMessageList(threadId)
        } else {
            const data = await getThreadMessages(threadId);
            const messages = data.data;

            // 获取最后一条消息
            const lastMessage = messages[0];  // 获取第一条消息

            if (lastMessage.content[0].type === "text") {
                // 按句号分割成多句话
                const sentences = lastMessage.content[0].text.value.split('。').filter(sentence => sentence.trim().length > 0);

                let accumulatedText = '';  // 初始化累积的文本内容

                // 遍历句子，并逐步更新消息内容
                sentences.forEach((sentence, index) => {
                    let delay = sentence.length * 250;
                    if (index === 0) {
                        delay = 0
                    }
                    setTimeout(() => {
                        // 累积当前句子
                        accumulatedText += sentence + "。";

                        // 创建 messages 的副本，保持不变性
                        const updatedMessages = [...messages];
                        updatedMessages[0] = {
                            ...updatedMessages[0],
                            content: [
                                {
                                    ...updatedMessages[0].content[0],
                                    text: {
                                        ...updatedMessages[0].content[0].text,
                                        value: accumulatedText  // 设置为累积的文本内容
                                    }
                                }
                            ]
                        };
                        // 更新状态
                        setMessages(updatedMessages);
                    }, delay); // 每句话的加入间隔1秒
                });
            }
        }

    };

    // 在对话新建时
    const newThreadHandler = async () => {
        await createThread().then(data => {
            if (data) {
                setThreadId(data.id);
                console.log(data.id)
                localStorage.removeItem("thread_id");
                localStorage.setItem("thread_id", JSON.stringify(data.id));
                setMessages([]);
                handleSend(data.id);  // 传入新的 threadId
                return data.id;
            }
        });
    };

    // 复制消息
    const handleCopy = (content) => {//复制当前内容
        navigator.clipboard.writeText(content)
    };

    // 删除消息
    const handleDeleteMessage = async (msg) => {//删除当前的一句对话
        setIsUserScrolling(true)
        deleteMessageApi(threadId, msg.id).then(data => {
            setMessages(prevMessages => prevMessages.filter(message => message.id !== msg.id));
        })
    }

    // 删除消息并刷新
    const handleRefreshMessage = async (msg, msgIndex) => {
        try {
            setIsUserScrolling(true);

            const endIndex = messages.length - 1 - msgIndex;

            // 生成删除消息的Promise数组
            const deletePromises = [];
            for (let i = 0; i <= endIndex; i++) {
                deletePromises.push(deleteMessageApi(threadId, messages[i].id));
            }

            // 等待所有删除操作完成
            await Promise.all(deletePromises);
            console.log('所有删除操作已完成');

            // 更新消息状态，过滤掉已经删除的消息
            setMessages(prevMessages => prevMessages.filter(message => message.id !== msg.id));

            // 调用 postRunByThreadBot 和 getMessageReply
            await postRunByThreadBot(threadId, draftInstructions);
            await getMessageReply(threadId);
        } catch (error) {
            console.error('处理刷新消息时发生错误:', error);
            Message.error('刷新消息时发生错误，请稍后再试。');
        } finally {
            setIsUserScrolling(false);
        }
    };

    // 发送消息
    const handleSend = async (threadId) => {
        console.log(draftInstructions);
        try {
            if (inputValue.trim() !== '') {
                setIsUserScrolling(false);
                disableValue.current = true;

                await postMessageByThreadBot(threadId, "user", inputValue, null);
                await getMessageList(threadId);
                setInputValue('');
                disableValue.current = false;

                await postRunByThreadBotCloud(threadId, draftInstructions);
                await getMessageReply(threadId);
            } else {
                await postRunByThreadBotCloud(threadId, draftInstructions);
                await getMessageReply(threadId);
            }
        } catch (error) {
            console.error("Error during message send:", error);
            Message.error("发送消息时出错，请稍后再试。");  // 显示错误信息给用户
        }
    };

    // 上传图片
    const handleUploadPic = (currentFile) => {
        setFile({
            ...currentFile,
            url: URL.createObjectURL(currentFile.originFile),
        })

        if (currentFile.status === "uploading") {
            uploadPictureFileAPi(currentFile.originFile).then(data => {
                postMessageByThreadBot(threadId, "user", inputValue, data).then(data => {
                    getMessageList(threadId).then(data => {
                        setFile(null)
                        postRunByThreadBot(threadId).then(data => {
                            getMessageReply(threadId);
                        })
                    });
                });
            });
        }

    };

    return (
        <Space direction="vertical"
               style={{height: "100vh", boxShadow: '-2px 0 2px rgba(0, 0, 0, 0.05)', paddingLeft: 10}}>
            <Space
                style={{
                    width: "30vw",
                    height: 40,
                    marginTop: 8,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}
            >
                <h3>聊天测试</h3>
                <Tabs
                    type="text"
                    activeTab={replyMode}
                    onChange={changeReplyMode}
                >
                    <TabPane key="personal" title="拟人回复"/>
                    <TabPane key="quick" title="快速回复"/>
                </Tabs>
                <LaunchModal selectedEmployee={selectedEmployee}/>
            </Space>

            <Space direction='vertical'>
                <Space direction="vertical"
                       style={{
                           width: "30vw",
                           height: "82vh",
                           overflowY: 'auto',
                           scrollbarWidth: 'thin',
                           scrollbarColor: '#EEE white'
                       }} ref={contentRef}>
                    {messages.length === 0 ? <Empty description='暂无聊天数据' style={{
                        marginTop: "30vh",
                    }}/> : messages.slice().reverse().map((msg, index) => {
                        return (
                            <Space key={index} align={"start"}>
                                <Avatar>
                                    <img alt='avatar'
                                         src={msg.role === "user" ? "https://pic1.zhimg.com/70/v2-53c1bc420d925bbc55d3ebddc1f1a091_1440w.avis?source=172ae18b&biz_tag=Post" :
                                             "https://pic1.zhimg.com/70/v2-73543239e7ae941adf7d3a4d62385951_1440w.avis?source=172ae18b&biz_tag=Post"
                                         }/>
                                </Avatar>
                                <Space direction={"vertical"}>
                                    <Space direction='horizontal'>
                                        <Text>{msg.role === "user" ? "用户" : "销售"}</Text>
                                        <Text type="secondary" style={{fontSize: '12px'}}>
                                            {formatTimestampToTime(msg.created_at)}
                                        </Text>
                                    </Space>
                                    <Space direction={"vertical"}>
                                        {msg.content.map((item, contentIndex) => {
                                            if (item.type === "text" && item.text && item.text.value) {
                                                return (
                                                    <React.Fragment key={`${msg.id}-${contentIndex}`}>
                                                        {item.text.value.split(/(?<=[?？!。])/).map((sentence, sentenceIndex) => (
                                                            <Space key={`${msg.id}-${contentIndex}-${sentenceIndex}`}
                                                                   align={"center"}>
                                                                <Text
                                                                    style={{
                                                                        background: msg.role === "user" ? "#366ef4" : "#f7f7fa",
                                                                        color: msg.role === "user" ? "#000000" : "#ffffff",
                                                                        padding: "10px",
                                                                        borderRadius: "8px",
                                                                        backgroundColor: msg.isTyping
                                                                            ? "#65B0F4"
                                                                            : msg.role === "user"
                                                                                ? "#F7F7FA"
                                                                                : "#366EF4",
                                                                        display: "inline-block",
                                                                        // width: "auto",
                                                                    }}
                                                                >
                                                                    {msg.role === "user"
                                                                        ? sentence // 用户消息保留所有标点
                                                                        : sentence.replace(/[？?！!。，,；;、]+$/g, "") // 非用户消息移除末尾标点
                                                                    }
                                                                </Text>
                                                                <Space>
                                                                    {msg.role !== "user" && (
                                                                        <Button size='mini' icon={<IconCopy/>}
                                                                                onClick={() => handleCopy(
                                                                                    msg.role === "user"
                                                                                        ? sentence
                                                                                        : sentence.replace(/[？?！!。，,；;、]+$/g, "")
                                                                                )}
                                                                                type="text"
                                                                                style={{color: 'black'}}
                                                                        />
                                                                    )}
                                                                    {/* 如果是最后一个句子且是助手的消息才显示按钮 */}
                                                                    {sentenceIndex === 0 && (
                                                                        <Space>
                                                                            <Button size='mini' icon={<IconDelete/>}
                                                                                    onClick={() => handleDeleteMessage(msg)}
                                                                                    type="text"
                                                                                    style={{color: 'black'}}
                                                                            />
                                                                            {msg.role !== "user" && (
                                                                                <Tooltip content="重新回答">
                                                                                    <Button size='mini'
                                                                                            icon={<IconRefresh/>}
                                                                                            onClick={() => handleRefreshMessage(msg, index)}
                                                                                            type="text"
                                                                                            style={{color: 'black'}}
                                                                                    />
                                                                                </Tooltip>
                                                                            )}
                                                                        </Space>
                                                                    )}
                                                                </Space>
                                                            </Space>
                                                        ))}
                                                    </React.Fragment>
                                                );
                                            } else if (item.type === "image_file" && imageUrls[item.image_file.file_id]) {
                                                return (
                                                    <Space key={item.image_file.file_id}>
                                                        <img
                                                            src={imageUrls[item.image_file.file_id]}
                                                            alt="用户图片"
                                                            style={{width: '250px', height: 'auto'}}/>
                                                        <Button size='mini' icon={<IconDelete/>}
                                                                onClick={() => handleDeleteMessage(msg)}
                                                                type="text"
                                                                style={{color: 'black'}}></Button>

                                                    </Space>
                                                );
                                            }
                                            return null;
                                        })}
                                    </Space>
                                </Space>
                            </Space>
                        )
                    })}
                </Space>


                <Space style={{marginTop: "2vh", paddingLeft: 4}}>
                    <Tooltip content="清空对话记录">
                        <Button type='text'
                                shape='circle'
                                onClick={newThreadHandler}
                                icon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                           viewBox="0 0 16 16" fill="currentColor">
                                    <path
                                        d="M2 6h4V2.333C6 1.597 6.597 1 7.334 1h1.333C9.403 1 10 1.597 10 2.333V6h4c.737 0 1.334.597 1.334 1.333V14c0 .736-.597 1.333-1.334 1.333H2A1.333 1.333 0 0 1 .667 14V7.333C.667 6.597 1.264 6 2 6Zm5.334-3.667v4a1 1 0 0 1-1 1H2v1.334h12V7.333H9.667a1 1 0 0 1-1-1v-4H7.334ZM2 10v4h2.667v-1.667a.667.667 0 0 1 1.333 0V14h1.334v-2a.667.667 0 1 1 1.333 0v2H10v-1.667a.667.667 0 0 1 1.334 0V14H14v-4H2Z"/>
                                </svg>}

                        />
                    </Tooltip>
                    <Input
                        style={{height: '5vh',
                            width: 'calc(30vw - 80px)',
                            marginLeft: 4}}
                        disabled={disableValue.current}
                        value={inputValue}
                        onChange={(value) => setInputValue(value)}
                        onPressEnter={() => handleSend(threadId)}
                        suffix={
                            <Upload
                                showUploadList={false}
                                fileList={file ? [file] : []}
                                multiple={false}
                                onChange={(_, currentFile) => handleUploadPic(currentFile)}
                                accept=".png,.jpeg,.jpg"
                            >
                                <Button type="text" size={"mini"} icon={<IconFileImage/>} style={{color: 'black'}}>
                                </Button>
                            </Upload>
                            // <Popover
                            //          size="small"
                            //          content={
                            //              <div
                            //                  style={{height: 250, minWidth: 200, textAlign: "center", display: "flex"}}>
                            //                  <Space direction={"vertical"} style={{width: "100%"}} align={"center"}>
                            //                      {pic && pic.url ?
                            //                          <img
                            //                              src={pic.url}
                            //                              alt="预览图片"
                            //                              style={{width: 150}}
                            //                          /> :
                            //                          <Result
                            //                              title='暂无图片'
                            //                          ></Result>}
                            //                      <Button
                            //                          type={'text'}
                            //                          icon={<IconCloseCircle/>}
                            //                          style={{
                            //                              display: pic && pic.url ? 'inline' : 'none'
                            //                          }}
                            //                          shape="circle"
                            //                          onClick={() => setPic({})}
                            //                      />
                            //                  </Space>
                            //              </div>
                            //          }>
                            //
                            // </Popover>
                        }
                    />
                    <Tooltip content="发送聊天">
                        <Button
                            type={inputValue === '' ? "text" : "text"}
                            icon={<IconSend style={{width: "20", height: "20", marginLeft: 10, marginTop: 3}}/>}
                            onClick={() => handleSend(threadId)}  // 传入当前的 threadId
                            disabled={inputValue === ''}
                        />
                    </Tooltip>
                </Space>
            </Space>
        </Space>
    );
};

export default ChatTest;
