import React, { useState, useEffect, useRef } from 'react';
import { Button, Message, Space, Table } from '@arco-design/web-react';
import { addCaseApi, deleteCaseApi, getCasesApi, updateCaseApi } from '../../../api/normalApi';
import PropTypes from 'prop-types';
import CaseCardModal from './CaseCardModal';
import { uploadFileAPi } from '../../../api/openapi';

const CaseMaterial = ({ columns, tableWidth }) => {
    const [caseList, setCaseList] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const editMode = useRef('');
    const [currentCase, setCurrentCase] = useState('');

    // 获取案例列表
    const getCases = async () => {
        try {
            const { data } = await getCasesApi();
            const casesWithKey = data.map((item, index) => ({
                ...item,
                key: index + 1,
            }));
            setCaseList(casesWithKey);
        } catch (error) {
            Message.error('获取案例列表失败，请稍后重试');
        }
    };

    const extractFileName = (filePath) => {
        if (!filePath) return ''; // 如果路径为空，返回空字符串

        // 使用正则表达式提取最后一个斜杠后面的部分
        return filePath.split('/').pop();
    }

    useEffect(() => {
        getCases();
    }, []);

    // 新增案例行
    const handleAdd = () => {
        setModalVisible(true);
        editMode.current = 'add';
        setCurrentCase('');
    };

    // 编辑案例
    const handleEdit = (record) => {
        setModalVisible(true);
        editMode.current = 'edit';
        setCurrentCase(record);
    };

    // 删除案例
    const handleDelete = async () => {
        if (currentCase && currentCase.id) {
            const response = await deleteCaseApi(currentCase.id);
            if (response.status === 'success') {
                Message.success('删除案例成功');
                setModalVisible(false);
                await getCases();
            } else {
                Message.error('删除案例失败');
                setModalVisible(false);
            }
        }
    };

    // 保存案例
    const handleSave = async (updatedCase, currentFile) => {

        //文件上传到openai
        let fileDetail;
        if (currentFile && currentFile.isNew) {
            fileDetail = await uploadFileAPi(currentFile.originFile);
        }
        const newCase = {
            ...updatedCase,
            case_name: updatedCase.case_name,
            openai_file_id: fileDetail ? fileDetail.id : updatedCase.openai_file_id,
            description: updatedCase.description,
            file_url: fileDetail ? fileDetail.filename : updatedCase.file_url
        }

        if (editMode.current === 'add') {
            const response = await addCaseApi(newCase);
            if (response.status === "success") {
                Message.success('新增案例成功');
                await getCases();
            }
        } else if (editMode.current === 'edit') {
            const response = await updateCaseApi(newCase.id, newCase);
            if (response.status === "success") {
                Message.success('修改案例成功');
                await getCases();
            }
        }
        setModalVisible(false);
    };

    // 定义所有可能的列
    const allColumns = [
        {
            title: '编号',
            dataIndex: 'key',
            width: 70,
        },
        {
            title: '案例/资料名称',
            dataIndex: 'case_name',
        },
        {
            title: '案例描述',
            dataIndex: 'description',
        },
        {
            title: '案例文件',
            dataIndex: 'file_url',
            render: (text) => (
                <a href={text} target="_blank" rel="noopener noreferrer">
                    {text ? extractFileName(text) : '暂无文件'}
                </a>
            ),
        },
        {
            title: (
                <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                    <Button size={'small'} type="primary" onClick={handleAdd}>新建</Button>
                </div>
            ),
            dataIndex: 'actions',
            align: 'right', // 将这一列内容向右对齐
            render: (_, record) => (
                <div style={{ textAlign: 'right' }}>
                    <Button size={'small'} onClick={() => handleEdit(record)}>编辑</Button>
                </div>
            ),
        },
    ];

    const displayedColumns = columns ? allColumns.filter(col => columns.includes(col.dataIndex)) : allColumns;

    return (
        <Space direction='vertical' style={{ width: "100%" }}>
            <Table
                size={'small'}
                columns={displayedColumns}
                data={caseList}
                pagination={false}
                rowKey="key"
                style={{ minWidth: tableWidth }}
            />
            <CaseCardModal
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
                caseData={currentCase}
                setCaseData={setCurrentCase}
                handleSave={handleSave}
                handleDelete={handleDelete}
            />
        </Space>
    );
};

CaseMaterial.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string),
    tableWidth: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export default CaseMaterial;
