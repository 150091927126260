// AssistantInfo.js
import React from 'react';
import { Space, Tag, Tabs } from '@arco-design/web-react';
import SalesMaterial from "../../SalesMaterial/SalesMaterial";
import SalesProcess from "../../AgentList/EditPage/components/EditPage/SalesProcess";

const TabPane = Tabs.TabPane;

/**
 * AssistantInfo Component
 *
 * This component displays the assistant's information and associated tabs.
 * It is self-contained and does not require external data via props.
 */
const AssistantInfo = () => {
    // Define the assistant information internally
    const assistantInfo = {
        name: "小希老师",
        status: {
            text: "正在服务",
            color: "green",
        },
        todayReception: 59,
        tabs: [
            {
                key: '1',
                title: '商品列表',
                content: <SalesMaterial
                    productColumns={['name', 'price']}
                    caseColumns={['case_name', 'description']}
                />,
            },
            // {
            //     key: '2',
            //     title: '订单列表',
            //     content: null, // Replace with desired content
            // },
            // {
            //     key: '3',
            //     title: '用户画像',
            //     content: null, // Replace with desired content
            // },
            {
                key: '4',
                title: '销售流程',
                content: <SalesProcess/>
            },
        ],
    };

    return (
        <Space direction="vertical" style={{marginRight:10}}>
            <Space align="center" style={{marginLeft: 5, marginTop: 15 }}>
                <h3 style={{ marginTop: 0, marginBottom: 0, marginLeft: 5 }}>
                    {assistantInfo.name}
                </h3>
                <Tag color={assistantInfo.status.color}>
                    {assistantInfo.status.text}
                </Tag>
                <span>今日接待：{assistantInfo.todayReception} 人</span>
            </Space>
            <Tabs defaultActiveTab={assistantInfo.tabs.length > 0 ? assistantInfo.tabs[0].key : '1'}
                  style={{ marginTop: 8, marginLeft: 8}}
                  type="card-gutter">
                {assistantInfo.tabs.map(tab => (
                    <TabPane
                        key={tab.key}
                        title={tab.title}
                        style={{ marginLeft: 10, marginRight: 10 }}
                    >
                        {tab.content || <div>暂无内容</div>} {/* Placeholder content */}
                    </TabPane>
                ))}
            </Tabs>
        </Space>
    );
};

// Optional: Define PropTypes if you decide to accept props in the future
AssistantInfo.propTypes = {
    // No props required as data is internal
};

export default AssistantInfo;