import React from 'react';
import {Input, Space} from '@arco-design/web-react';

function SalesProcess({salesProcess, setSalesProcess}) {

    const handleSalesProcessChange = (e) => {
        console.log(e);
        setSalesProcess(e);
    }

    return (
        <Space>
            <Input.TextArea
                value={salesProcess}
                onChange={(e) =>
                    handleSalesProcessChange(e)
                }
                placeholder="请输入销售流程"
                style={{width: "41vw",height: 200}}
            />
        </Space>
    );
}

export default SalesProcess;
