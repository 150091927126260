// utils/format.js
export const formatTimestampToTime = (timestamp) => {
    // 将 UNIX 时间戳转换为毫秒，并创建 Date 对象
    const date = new Date(timestamp * 1000);

    // 获取时分秒，并确保它们是两位数格式
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
};