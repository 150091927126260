// ProductList.jsx
import React, { useState, useEffect, useRef } from 'react';
import { Table, Input, Button, Message, Space } from '@arco-design/web-react';
import { addProductApi, updateProductApi, getProductsApi, deleteProductApi } from '../../../api/normalApi';
import PropTypes from 'prop-types';
import ProductCardModal from "./ProductCardModal";

const ProductList = ({ columns, tableWidth }) => {
    const [productList, setProductList] = useState([]);
    const [editableRow, setEditableRow] = useState(null);
    const [addDisabled, setAddDisabled] = useState(false);
    const [modalVisible, setModalVisible] = useState(false); // 控制对话框可见性
    const editMode = useRef('')
    const [product, setProduct] = useState();//传递过去的商品
    const [newProduct, setNewProduct] = useState();

    // 获取商品列表
    const getProducts = async () => {
        try {
            const { data } = await getProductsApi();
            const productsWithKey = data.map((item, index) => ({
                ...item,
                key: index + 1,
                isNew: false,
            }));
            setProductList(productsWithKey);
        } catch (error) {
            Message.error('获取商品列表失败，请稍后重试');
        }
    };


    // 新增行
    const handleAdd = () => {
        setModalVisible(true)
        editMode.current = 'add'
        setProduct('')
    }

    const handleDelete = () => {
        if (product && product.id) {
            deleteProductApi(product.id).then(data => {
                if (data.status === 'success') {
                    Message.success("商品删除成功")
                    setModalVisible(false)
                    getProducts()
                } else {
                    Message.error("商品删除失败")
                    console.log(data)
                    setModalVisible(false)
                }
            })
        }
    }

    // 编辑行
    const handleEdit = (record) => {
        editMode.current = 'edit';
        setProduct({ ...record })
        setModalVisible(true)
    };

    // 保存行
    const handleSave = async () => {
        if (newProduct) {
            if (!newProduct.name || newProduct.price <= 0 || !newProduct.description || !newProduct.urls) {
                Message.error('请完善商品信息！');
                editMode.current = ''
            }
        }
        if (editMode.current === 'add') {
            // 新增商品
            const response = await addProductApi({ ...newProduct });
            if (response.status === "success") {
                await getProducts();
                Message.success('新增商品成功');
            }
        } else if (editMode.current === 'edit') {
            // 编辑商品
            const response = await updateProductApi(newProduct.id, { ...newProduct });
            if (response.status === "success") {
                await getProducts();
                Message.success('修改商品成功');
            }
        }

        // 更新商品列表，移除 isNew 标志
        // const updatedProductList = productList.map((item) =>
        //     item.key === key ? { ...item, isNew: false } : item
        // );
        // setProductList(updatedProductList);
        setEditableRow(null);
        setAddDisabled(false);

    };

    // 处理字段变化
    const handleFieldChange = (value, key, field) => {
        const newData = productList.map((item) => {
            if (item.key === key) {
                return { ...item, [field]: value };
            }
            return item;
        });
        setProductList(newData);
    };

    // 定义所有可能的列
    const allColumns = [
        {
            title: '编号',
            dataIndex: 'key',
            width: 70,
        },
        {
            title: <Space>
                商品名称
            </Space>,
            dataIndex: 'name',
            render: (text, record) => {
                return editableRow === record.key ? (
                    <Input
                        value={record.name}
                        onChange={(value) => handleFieldChange(value, record.key, 'name')}
                    />
                ) : (
                    text
                );
            },
        },
        {
            title: '价格',
            dataIndex: 'price',
            render: (text, record) => {
                return editableRow === record.key ? (
                    <Input
                        value={record.price}
                        onChange={(value) => handleFieldChange(value, record.key, 'price')}
                    />
                ) : (
                    text
                );
            },
        },
        {
            title: '商品介绍',
            dataIndex: 'description',
            render: (text, record) => {
                return editableRow === record.key ? (
                    <Input
                        value={record.description}
                        onChange={(value) => handleFieldChange(value, record.key, 'description')}
                    />
                ) : (
                    text
                );
            },
        },
        {
            title: '访问链接',
            dataIndex: 'urls',
            render: (text, record) => {
                return editableRow === record.key ? (
                    <Input
                        value={record.urls}
                        onChange={(value) => handleFieldChange(value, record.key, 'urls')}
                    />
                ) : (
                    <a href={text} target="_blank" rel="noopener noreferrer">
                        {text || '暂无链接'}
                    </a>
                );
            },
        },
        {
            title: <Button
                size={'small'}
                type="primary"
                onClick={handleAdd}
                disabled={addDisabled}
            >新建</Button>,
            dataIndex: 'actions',
            width: 80,
            render: (_, record) => {
                return (
                    <Button onClick={() => {
                        handleEdit(record)
                    }} size={'small'} >编辑</Button>
                )
            },
        },
    ];

    // 根据传入的 columns prop 过滤要显示的列，如果未传入，则显示所有列
    const displayedColumns = columns
        ? allColumns.filter(col => columns.includes(col.dataIndex))
        : allColumns;

    useEffect(() => { //初始化的时候请求获取全部的商品
        getProducts();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        handleSave()
        // eslint-disable-next-line
    }, [newProduct]);

    return (
        <Space direction='vertical' style={{ width: "100%" }}>
            <Table
                size={'small'}
                columns={displayedColumns}
                data={productList}
                pagination={false}
                style={{ minWidth: tableWidth }}
            />
            <ProductCardModal
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
                product={product}
                setProduct={setNewProduct}
                handleDelete={handleDelete}
            />
        </Space>
    );
};

// 定义 propTypes
ProductList.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string),
    tableWidth: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export default ProductList;