// SalesMaterial.jsx
import { Space } from '@arco-design/web-react';
import React from 'react';
import ProductList from "./components/ProductList";
import PropTypes from 'prop-types';
import CaseMaterial from './components/CaseMaterial';

const SalesMaterial = ({
    productColumns,
    caseColumns,
    productTableStyle,
    caseTableStyle
}) => {
    return (
        <Space direction={"vertical"}>
            <h3>商品列表</h3>
            <ProductList
                columns={productColumns}
                tableStyle={productTableStyle}
            />
            <h3>案例列表</h3>
            <CaseMaterial
                columns={caseColumns}
                tableStyle={caseTableStyle}
            />
        </Space>
    )
};

// 定义 propTypes
SalesMaterial.propTypes = {
    productColumns: PropTypes.arrayOf(PropTypes.string),
    caseColumns: PropTypes.arrayOf(PropTypes.string),
    productTableStyle: PropTypes.object,
    caseTableStyle: PropTypes.object
};

export default SalesMaterial;