import React, { useState, useEffect } from 'react';
import { Button, Input, Upload, Message, Modal, Space } from '@arco-design/web-react';
import COS from 'cos-js-sdk-v5';
const { TextArea } = Input;

const CaseCardModal = ({ visible, onClose, caseData, setCaseData, handleSave, handleDelete }) => {
    const [caseName, setCaseName] = useState('');
    const [caseDescription, setCaseDescription] = useState('');
    const [fileUrl, setFileUrl] = useState('');
    const [currentFile, setCurrentFile] = useState(null);
    const storedUsername = localStorage.getItem('user_name');

    // 当 caseData 改变时，更新组件内的状态
    useEffect(() => {
        if (caseData) {
            setCaseName(caseData.case_name || '');
            setCaseDescription(caseData.description || '');
            setFileUrl(caseData.file_url || '');
            setCurrentFile({ ...caseData, name: caseData.file_url, isNew: false })
        } else {
            setCaseName('');
            setCaseDescription('');
            setFileUrl('');
        }
    }, [caseData]);

    // 上传文件
    const handleFileUpload = async (curFile) => {
        const nowCase = caseData;
        nowCase.file_url = `${storedUsername}/${curFile.name}`;

        if (curFile.status === "uploading") {
            // 保存文件
            setCurrentFile({ ...curFile, isNew: true }); // 更新 currentFile

            // COS 永久密钥上传文件
            const cos = new COS({
                SecretId: 'AKIDxmPF0xDKG1c3JNI1XqwqZ7UggV9ctzym', // 永久密钥
                SecretKey: 'hBSn4XwDKIE5tcGqY8WhfLvKivxtgK3b', // 永久密钥
            });
            cos.putObject(
                {
                    Bucket: 'zchat-1256349444', // 存储桶名称
                    Region: 'ap-guangzhou', // 存储桶的区域
                    Key: `${storedUsername}/${curFile.name}`, // 上传文件名
                    Body: curFile.originFile, // 文件对象
                },
                function (err, data) {
                    if (err) {
                        console.error('上传出错:', err);
                    } else {
                        Message.success('上传文件成功');
                    }
                }
            );
        }
    };

    // 保存或更新案例
    const handleOk = () => {
        if (!caseName) {
            Message.error("请填入案例名字");
            return;
        }
        const updatedCase = {
            ...caseData,
            case_name: caseName,
            description: caseDescription,
            file_url: fileUrl,
        };
        setCaseData(updatedCase);
        handleSave(updatedCase, currentFile);
    };

    return (
        <Modal
            style={{ width: '800px', height: '330px' }}
            visible={visible}
            onCancel={onClose}
            title="编辑案例"
            footer={
                <Space>
                    <Button type="primary" onClick={handleOk}>确认</Button>
                    <Button type='outline' onClick={onClose}>取消</Button>
                    {caseData && caseData.id ? (
                        <Button type="primary" status='danger' onClick={handleDelete}>删除</Button>
                    ) : null}
                </Space>
            }
        >
            <Input
                placeholder="案例名称"
                value={caseName}
                onChange={(value) => setCaseName(value)}
                style={{ marginBottom: '10px' }}
            />
            <TextArea
                placeholder="案例描述"
                value={caseDescription}
                onChange={(value) => setCaseDescription(value)}
                style={{ marginBottom: '10px' }}
            />
            <Space align='center'>
                <Upload
                    showUploadList={false}
                    onChange={(_, curFile) => handleFileUpload(curFile)}
                    style={{ marginBottom: '10px' }}
                >
                    <Button type="primary">上传文件</Button>
                </Upload>
                {currentFile && currentFile.name ? currentFile.name : <p>上传文件后会自动生成名称和描述</p>}
            </Space>
        </Modal>
    );
};

export default CaseCardModal;
